import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const OurScienceTechnology = () => {
    const seo = {
        metaDesc: 'OUR SCIENCE TECHNOLOGY - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'about'}>
            <SEO title="OUR SCIENCE TECHNOLOGY - Inventiv.org" canonical='/our-science-technology' seo={seo} />
            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Our Science Technology</h1>
                    </div>
                </div>
            </div>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 wow fadeInLeft delay-04s">
                            <p class="f-16 sec-clr text-center mb-3">
                                At Inventiv, we believe that science and technology is the bedrock of innovation and economic growth. Guided by curiosity and driven by a thirst for knowledge, we embrace the transformative power of tech innovation. Our commitment to scientific excellence shapes everything we do, from our educational programs to our free software.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/smiling-woman.webp" width="100%" alt="" />
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="f-16 sec-clr">
                                Inventiv’s ProvisionalBuilder® software embraces a do-it-yourself (DIY) approach to invention that empowers inventors to bring their ideas to life under the protection of the patent system. We understand that inventors often have abstract concepts and rough sketches as their starting point, and we are here to guide them through the journey of transforming those ideas into fully-fledged patent-pending inventions.
                                <br /><br />
                                Our process begins by capturing the essence of the invention. We recognize that inventors may have visionary ideas that are difficult to articulate in traditional written formats. Instead, our free ProvisionalBuilder® software encourages inventors to detail their abstract concepts and initial sketches in the summary of the inventions and the drawings. These drawings serve as a visual representation of their vision and provide a starting point for further development.
                                <br /><br />
                                Drawing upon the inventors’ abstracts and sketches, our ProvisionalBuilder® software works closely with inventors to refine and translate their ideas into detailed, high-resolution descriptions complete with annotated part-list for each figure. We believe in the power of collaboration and understanding, ensuring that the inventors’ intentions and unique insights are fully captured in the final invention description.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3 bg-light-blue our-science-tech-sec-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <p class="f-16 sec-clr">
                                To facilitate the inventors’ journey, we are also developing generative AI technology. This cutting-edge technology assists inventors in completing provisional applications based on the provided summary and the part list extracted from their drawings. The AI system analyzes the inventors’ input and generates provisional application templates, streamlining the application process and saving valuable time and effort.
                                <br /><br />
                                At Inventiv, we recognize that every inventor’s journey is unique. We provide personalized guidance and support throughout the invention process, taking into account the inventors’ individual needs and aspirations. Our experienced team of mentors and experts is here to assist inventors at every step, helping them navigate the complexities of invention, patent applications, and beyond.
                                <br /><br />
                                Through our DIY approach, we empower inventors to take ownership of their inventions. We believe that inventors possess unique insights and expertise that drive innovation. By guiding inventors to transform their abstract ideas and sketches into detailed invention descriptions, we enable them to communicate their inventions effectively and realize their full potential.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="img-1 text-center mb-1">
                                        <img src="assets/img/banner/people-are-discussing-about-the-meeting.webp" width="100%" alt="" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="img-2 text-center mb-1">
                                        <img src="assets/img/banner/office-meeting-room.webp" width="100%" alt="" />
                                    </div>
                                    <div class="img-3 text-center mb-1">
                                        <img src="assets/img/banner/client-meet.webp" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-3 pb-3 mt-3 mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/discuss-the-meeting-points.webp" width="100%" alt="" />

                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="f-16 sec-clr">
                                Join us at Inventiv and embark on your patent journey. Let your abstract ideas and rough sketches be the starting point of an exciting adventure. Together, we will refine, document, and protect your invention, paving the way for future success. With our generative AI technology and expert guidance, you can confidently navigate the invention landscape and bring your ideas to life. Get ready to unleash your inventiveness and make a lasting impact on the world.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
};

export default OurScienceTechnology;